// React functional component
import React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";

const CoinPrice = (props) => {
  const [coin, setCoin] = useState(true);

  const setError = () => {
    setCoin(false);
  };

  return (
    <div className="coinpricegroup">
      <div className="content">
        <Row>
          {coin ? (
            <Col xs="4">
              <img
                src={`/icons/${props.name.toLowerCase()}.svg`}
                alt={props.name}
                onError={setError}
              />
            </Col>
          ) : (
            ""
          )}
          <Col xs="6">
            <div className="">
              <h1>{props.name}</h1>
            </div>
          </Col>
          <Col xs="12">
            <div className="">
              <h2>
                {parseFloat(parseFloat(props.amount).toPrecision(5))}{" "}
                {props.base}
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CoinPrice;
