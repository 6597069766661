import React from "react";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";

const Heading = (props) => {
  const [baseInput, setBaseInput] = useState("");

  const changeBase = () => {
    props.changeBase(baseInput);
    setBaseInput("");
  };

  return (
    <div className="headings">
      <Row className="fullRow">
        <Col md="6">
          <h1>Crypto Exchange Rates</h1>
        </Col>
        <Col md="3">
          <div className="search">
            <input
              placeholder="Search"
              onChange={(e) =>
                props.filterEntries(e.target.value.toUpperCase())
              }
            />
          </div>
        </Col>
        <Col md="3">
          <div className="control">
            <input
              placeholder="Enter a currency"
              type="text"
              value={baseInput}
              onChange={(e) => setBaseInput(e.target.value)}
            />
            <button onClick={() => changeBase()}>Set Base</button>
            {props.error ? <label>Base currency not supported</label> : ""}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Heading;
