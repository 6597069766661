import "./App.css";
import React from "react";
import { useState, useEffect } from "react";
import CoinPrice from "./components/coinprice";
import { Row, Col } from "react-bootstrap";
import Heading from "./components/heading";

function App() {
  const [isLoading, setIsloading] = useState(true);
  const [base, setBase] = useState("USD");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    const url = `https://api.coinbase.com/v2/exchange-rates?currency=${base}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        let rates = data.data.rates;
        let res = [];

        for (let i = 0; i < ordering.length; i++) {
          if (rates[ordering[i]]) {
            let coin = ordering[i];
            let rate = 1 / rates[coin];
            res.push([coin, rate]);
            delete rates[coin];
          }
        }

        for (let key in rates) {
          res.push([key, 1 / rates[key]]);
        }
        setData(res);
        setFilter(res);
        setIsloading(false);
      });
  }, [base]);

  const changeBase = (baseInput) => {
    if (!ordering.includes(baseInput)) {
      setError(true);
      return;
    }
    if (baseInput === base) {
      setError(false);
      return;
    }
    setError(false);
    setBase(baseInput);
    setIsloading(true);
  };

  const filterEntries = (val) => {
    let res = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i][0].includes(val)) {
        res.push(data[i]);
      }
    }
    setFilter(res);
  };

  return (
    <div className="App">
      <Heading
        filterEntries={filterEntries}
        changeBase={changeBase}
        base={base}
        error={error}
      />
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <center>
            <Row className="fullRow">
              {filter.map((pair) => {
                return (
                  <Col key={pair}>
                    <CoinPrice name={pair[0]} amount={pair[1]} base={base} />
                  </Col>
                );
              })}
            </Row>
          </center>
        </div>
      )}
      <p className="footer">Data provided by Coinbase</p>
    </div>
  );
}

const ordering = [
  "BTC",
  "ETH",
  "USDT",
  "USDC",
  "SOL",
  "XRP",
  "ADA",
  "DOGE",
  "AVAX",
  "UST",
  "DOT",
  "SHIB",
  "WBTC",
  "MATIC",
  "CRO",
  "DAI",
  "LTC",
  "ATOM",
  "LINK",
  "UNI",
  "BCH",
  "APE",
  "XLM",
  "ALGO",
  "ETC",
  "ICP",
  "MANA",
  "FIL",
  "XTZ",
  "AXS",
  "ZEC",
  "EOS",
  "AAVE",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BYR",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUC",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "GBP",
  "GEL",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SEK",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STD",
  "SVC",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XAG",
  "XAU",
  "XBA",
  "XCD",
  "XDR",
  "XOF",
  "XPD",
  "XPF",
  "XPT",
  "XTS",
  "YER",
  "ZAR",
  "ZMW",
  "JEP",
  "GGP",
  "IMP",
  "GBX",
  "CNH",
  "ZWD",
  "ZWL",
  "VEF",
  "SGD",
  "USD",
  "BSV",
  "ETH2",
  "ZRX",
  "BAT",
  "LOOM",
  "KNC",
  "DNT",
  "MKR",
  "CVC",
  "OMG",
  "GNT",
  "SNT",
  "REP",
  "DASH",
  "OXT",
  "COMP",
  "ENJ",
  "REPV2",
  "BAND",
  "NMR",
  "CGLD",
  "UMA",
  "LRC",
  "YFI",
  "BAL",
  "REN",
  "NU",
  "YFII",
  "BNT",
  "GRT",
  "SNX",
  "STORJ",
  "SUSHI",
  "SKL",
  "ANKR",
  "CRV",
  "NKN",
  "OGN",
  "1INCH",
  "FORTH",
  "CTSI",
  "TRB",
  "POLY",
  "MIR",
  "RLC",
  "MLN",
  "GTC",
  "AMP",
  "CHZ",
  "KEEP",
  "LPT",
  "QNT",
  "BOND",
  "RLY",
  "CLV",
  "FARM",
  "MASK",
  "FET",
  "PAX",
  "ACH",
  "ASM",
  "PLA",
  "RAI",
  "TRIBE",
  "ORN",
  "IOTX",
  "QUICK",
  "REQ",
  "WLUNA",
  "TRU",
  "RAD",
  "COTI",
  "DDX",
  "SUKU",
  "RGT",
  "XYO",
  "ZEN",
  "AUCTION",
  "JASMY",
  "WCFG",
  "BTRST",
  "AGLD",
  "FX",
  "TRAC",
  "LCX",
  "ARPA",
  "BADGER",
  "KRL",
  "PERP",
  "RARI",
  "DESO",
  "API3",
  "NCT",
  "SHPING",
  "UPI",
  "AVT",
  "MDT",
  "VGX",
  "ALCX",
  "COVAL",
  "FOX",
  "MUSD",
  "GALA",
  "POWR",
  "GYEN",
  "ALICE",
  "INV",
  "LQTY",
  "PRO",
  "SPELL",
  "ENS",
  "DIA",
  "BLZ",
  "CTX",
  "ERN",
  "IDEX",
  "MCO2",
  "POLS",
  "SUPER",
  "UNFI",
  "STX",
  "GODS",
  "IMX",
  "RBN",
  "BICO",
  "GFI",
  "GLM",
  "MPL",
  "PLU",
  "FIDA",
  "ORCA",
  "CRPT",
  "QSP",
  "RNDR",
  "SYN",
  "AIOZ",
  "AERGO",
  "HIGH",
  "ROSE",
  "MINA",
];

export default App;
